/**
 * Created by Romeo on 2017.01.03..
 */

/* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginRight = "250px";
    document.getElementById("main").style.marginLeft = "-250px";
    document.getElementById("myOffcanvas").style.width = "100%";
    document.getElementById("myOffcanvas").style.opacity = 0.5;
}

/* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginRight = "0";
    document.getElementById("main").style.marginLeft = "0";
    document.getElementById("myOffcanvas").style.opacity = 0;
    document.getElementById("myOffcanvas").style.width = "0";
}


$(function () {

    /*if (stime == undefined) {
        var stime = 400;
    }*/

    $('#myr17-button').click(function () {
        $("#hamburger-menu").animate({right: -250}, 500);
        $("#page-wrap, header").animate({left: 0}, 500, function () {
            $("#menu-open-layer").css('width', 0);
        });
        $('#myr17-modal-close-canvas').show();
        $('#myr17-modal').show(400);
    });

    $('#myr17-modal-close, #myr17-modal-close-canvas').click(function () {
        $('#myr17-modal').hide(400);
        $('#myr17-modal-close-canvas').hide();
    });
});